:root {
    --blue: #3490dc;
    --indigo: #6574cd;
    --purple: #9561e2;
    --pink: #f66d9b;
    --red: #ee4242;
    --orange: #f6993f;
    --yellow: #ffed4a;
    --green: #38c172;
    --teal: #4dc0b5;
    --cyan: #6cb2eb;
    --white: #fff;
    --gray: #9e9b9a;
    --gray-dark: #494747;
    --primary: #edbf0d;
    --secondary: #373d20;
    --success: #38c172;
    --info: #6cb2eb;
    --warning: #ffed4a;
    --danger: #ee4242;
    --light: #fbf9f9;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
        "Liberation Mono", "Courier New", monospace;
}
*,
:after,
:before {
    box-sizing: border-box;
}
html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
footer,
header,
main,
nav,
section {
    display: block;
}
body {
    margin: 0;
    font-family: Lato, sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #1a1919;
    text-align: left;
    background-color: #fffefe;
}
[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}
h1,
h5,
h6,
ul {
    margin-top: 0;
}
ul {
    margin-bottom: 1rem;
}
a {
    color: #1a1919;
    text-decoration: none;
    background-color: transparent;
}
a:hover {
    color: #000;
    text-decoration: underline;
}
img {
    border-style: none;
}
img,
svg {
    vertical-align: middle;
}
svg {
    overflow: hidden;
}
button {
    border-radius: 0;
}
button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}
button,
input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    overflow: visible;
}
button {
    text-transform: none;
}
[type="button"],
[type="submit"],
button {
    -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}
[type="button"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}
[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}
h1,
h5,
h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
h1 {
    font-size: 2.25rem;
}
h5 {
    font-size: 1.125rem;
}
h6 {
    font-size: 0.9rem;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}
.no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}
.col-lg-1,
.col-lg-3,
.col-lg-8 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.order-1 {
    order: 1;
}
.order-2 {
    order: 2;
}
@media (min-width: 992px) {
    .col-lg-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-lg-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }
    .order-lg-1 {
        order: 1;
    }
    .order-lg-2 {
        order: 2;
    }
}
.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
    text-decoration: none;
}
.navbar {
    position: relative;
    padding: 0.5rem 1rem;
}
.navbar,
.navbar .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.navbar-brand {
    display: inline-block;
    padding-top: 0.32rem;
    padding-bottom: 0.32rem;
    margin-right: 1rem;
    font-size: 1.125rem;
    line-height: inherit;
    white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none;
}
.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}
.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}
.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.125rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none;
}
.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat 50%;
    background-size: 100% 100%;
}
@media (max-width: 991.98px) {
    .navbar-expand-lg > .container {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-lg > .container {
        flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}
.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-nav .nav-link {
    color: #1a1919;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #edbf0d;
}
.navbar-light .navbar-nav .nav-link.active {
    color: #1a1919;
}
.navbar-light .navbar-toggler {
    color: #1a1919;
    border-color: #fffefe;
}
.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='%231A1919' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #fffefe;
    border-radius: 0.3rem;
}
@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}
.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}
.card-img-top {
    flex-shrink: 0;
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}
.card-deck .card {
    margin-bottom: 15px;
}
@media (min-width: 576px) {
    .card-deck {
        display: flex;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .card-deck .card {
        flex: 1 0 0%;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.bg-white {
    background-color: #fff !important;
}
.border-0 {
    border: 0 !important;
}
.rounded-0 {
    border-radius: 0 !important;
}
.m-0 {
    margin: 0 !important;
}
.mx-0 {
    margin-right: 0 !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.mx-0 {
    margin-left: 0 !important;
}
.ml-3 {
    margin-left: 1rem !important;
}
.mb-4 {
    margin-bottom: 0.25rem !important;
}
.ml-8 {
    margin-left: 0.5rem !important;
}
.mt-16 {
    margin-top: 1rem !important;
}
.mb-16 {
    margin-bottom: 1rem !important;
}
.mr-24 {
    margin-right: 1.5rem !important;
}
.mb-24 {
    margin-bottom: 1.5rem !important;
}
.ml-24 {
    margin-left: 1.5rem !important;
}
.mb-40 {
    margin-bottom: 2.5rem !important;
}
.mr-48 {
    margin-right: 3rem !important;
}
.p-0 {
    padding: 0 !important;
}
.pb-16 {
    padding-bottom: 1rem !important;
}
.p-24 {
    padding: 1.5rem !important;
}
.pt-24 {
    padding-top: 1.5rem !important;
}
.pr-24 {
    padding-right: 1.5rem !important;
}
.py-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
}
.mt-n40 {
    margin-top: -2.5rem !important;
}
.mt-auto {
    margin-top: auto !important;
}
.mr-auto,
.mx-auto {
    margin-right: auto !important;
}
.mx-auto {
    margin-left: auto !important;
}
@media (min-width: 576px) {
    .mx-sm-12 {
        margin-right: 0.75rem !important;
        margin-left: 0.75rem !important;
    }
    .ml-sm-32 {
        margin-left: 2rem !important;
    }
}
@media (min-width: 992px) {
    .mt-lg-0 {
        margin-top: 0 !important;
    }
    .mb-lg-32 {
        margin-bottom: 2rem !important;
    }
    .mr-lg-48 {
        margin-right: 3rem !important;
    }
    .pl-lg-8 {
        padding-left: 0.5rem !important;
    }
    .px-lg-48 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .pl-lg-80 {
        padding-left: 5rem !important;
    }
}
.flex-row {
    flex-direction: row !important;
}
.flex-column {
    flex-direction: column !important;
}
.justify-content-end {
    justify-content: flex-end !important;
}
.justify-content-center {
    justify-content: center !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
.align-items-center {
    align-items: center !important;
}
.align-self-end {
    align-self: flex-end !important;
}
.align-self-center {
    align-self: center !important;
}
@media (min-width: 992px) {
    .align-self-lg-end {
        align-self: flex-end !important;
    }
}
.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text-right {
    text-align: right !important;
}
.text-center {
    text-align: center !important;
}
.text-muted {
    color: #777474 !important;
}
.d-none {
    display: none !important;
}
.d-block {
    display: block !important;
}
.d-flex {
    display: flex !important;
}
@media (min-width: 768px) {
    .d-md-block {
        display: block !important;
    }
}
.container {
    max-width: 100% !important;
    width: 100% !important;
}
#search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 0.5s ease-in-out;
    transform: translateY(-100%) scale(0);
    opacity: 0;
    z-index: 2000;
}